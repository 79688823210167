// Here you can add other styles
body {
  font-family: monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.breadcrumb-item > a {
  text-decoration: none;
  color: #000;
}

.breadcrumb-item > a:hover {
  text-decoration: underline;
}

pre {
  font-size: 10px;
  padding: 5px 10px;
  background: #ddd;
  font-weight: bold;
  border-radius: 5px;
}

.clickable:hover {
  cursor: pointer;
}

a.btn-link {
  text-decoration: none;
}
a.btn-link.btn-primary {
  color: #fff;
}

tbody.no-border-last-child tr:last-child td,
tbody.no-border-last-child tr:last-child th {
  border-bottom: 0;
}

.btn.btn-danger {
  color: #fff;
}

.ml-3 {
  margin-left: 1rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.my-3 {
  margin: 1rem 0;
}
.col {
  margin-bottom: 1rem;
}

.fs-07 {
  font-size: 0.7rem;
}
label {
  line-height: 1;
}
.flex {
  display: flex;
}
.ai-end {
  align-items: end;
}
.cur-grap {
  cursor: grab;
}
.cur-pointer {
  cursor: pointer;
}
.w100 {
  width: 100%;
}
.w25 {
  width: 25%;
}
.w100px {
  width: 100px;
}
.w150px {
  width: 150px;
}
.h100 {
  height: 100%;
}
.flex-0 {
  flex: 0;
}
.form-icon-add {
  width: 2.4rem !important;
  height: 2.4rem !important;
  cursor: pointer;
  color: #3c4b64;
}
.pt-M {
  padding-top: 1.65rem;
}
.btn-config-add {
  height: 20px;
}
.h64px {
  height: 64px;
}
.ofy-auto {
  overflow-y: auto;
}
.mxh20rem {
  max-height: 20rem;
}
.mxh37rem {
  max-height: 37rem;
}
.min-w325px {
  min-width: 325px;
}
.w325px {
  width: 325px;
}
.flex-xy-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-y-center {
  display: flex;
  align-items: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-end {
  justify-content: flex-end;
}
.w-4rem {
  width: 4rem !important;
}
.h-2rem {
  height: 2rem !important;
}
.w-9rem {
  width: 9rem;
}
.relative {
  position: relative;
}
.bold {
  font-weight: bold;
}
.table-custom {
  border: 1px solid var(--cui-card-cap-bg, rgba(0, 0, 21, 0.03));
  border-radius: 10px;
}
.table-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-body .table-row:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-body .table-row {
  background-color: #fff;
  border-bottom: 1px solid var(--cui-card-cap-bg, rgba(0, 0, 21, 0.03));
}
.table-body .table-row:hover {
  background-color: var(--cui-card-cap-bg, rgba(0, 0, 21, 0.03));
}
.table-header {
  background-color: var(--cui-card-cap-bg, rgba(0, 0, 21, 0.03));
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #ccc;
}
.table-head-row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.form-check-input {
  cursor: pointer;
}
.modal-btn-close-test {
  width: 10.75rem;
}
.modal-btn-cancel {
  width: 5.938rem;
}
.color-red {
  color: #ff0000;
}
.fd-column {
  flex-direction: column;
}
.table-test-info tr td:last-of-type {
  border-left: 1px solid var(--cui-table-border-color);
}
.table-group-test-info td,
.table-group-test-info th {
  border-left: 1px solid var(--cui-table-border-color);
}
.table-group-test-info td:first-of-type,
.table-group-test-info th:first-of-type {
  border-left: 0;
}
.selects .col {
  width: 300px;
}
.border-0 {
  border: 0;
}
.table-promocodes {
  th:first-of-type,
  td:first-of-type {
    padding-left: 1rem;
  }
  th:last-of-type,
  td:last-of-type {
    padding-right: 1rem;
  }
}
